/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-relieve-pressure-in-the-inner-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-relieve-pressure-in-the-inner-ear",
    "aria-label": "how to relieve pressure in the inner ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Relieve Pressure in the Inner Ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Pressure in the inner ear can range from mildly uncomfortable to actively painful. Learn about what the pressure is, what causes it, how to relieve it and possible complications if the issue isn’t resolved promptly."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-exactly-is-inner-ear-pressure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-exactly-is-inner-ear-pressure",
    "aria-label": "what exactly is inner ear pressure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What exactly is inner ear pressure?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inner ear pressure happens when the pressure inside the ear behind the eardrum is different from the pressure outside the ear. Inner or middle ear pressure is regulated by eustachian tubes, small tubes connecting the middle ear to the meeting of the nasal cavity and the throat. This pathway is how the inner ear remains connected to the outside environment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The eustachian tubes can become narrowed or blocked, preventing proper pressure equalization and therefore causing inner ear pressure. The sensation of ear pressure creates the feeling of the ears being clogged or plugged up. It can consist of pressure only, but it can also involve pain and muffled hearing."), "\n", React.createElement(LandingPageCta, {
    copy: "I want to manage my hearing loss",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-causes-inner-ear-pressure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-inner-ear-pressure",
    "aria-label": "what causes inner ear pressure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What causes inner ear pressure?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are several types of pressure in the ear: pressure caused by a difference in air pressure and pressure caused by fluid buildup inside the ear. Air pressure differences are usually caused by a change of altitude. An imbalance of fluid in the inner ear (a condition called endolymphatic hydrops) can result from a sinus infection, ear infection, cold or allergies."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A third cause of ear stuffiness may occur and this is from a physical obstruction inside the ear. This obstruction can happen due to earwax buildup blocking the ear canal or a foreign object inside the ear."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-can-trigger-an-imbalance-in-ear-pressure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-can-trigger-an-imbalance-in-ear-pressure",
    "aria-label": "what can trigger an imbalance in ear pressure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What can trigger an imbalance in-ear pressure?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Common causes of inner ear pressure include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear barotrauma (airplane ear), when the difference in air pressure inside and outside the ear is pronounced enough to cause stress to the eardrum"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sinus infections causing swelling that can affect your eustachian tubes"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear infection leading to fluid buildup behind the eardrum"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Colds causing swelling and mucus inside the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Allergies inflaming the eustachian tubes preventing pressure equalization"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Excessive earwax buildup blocking the ear canal"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A foreign object stuck in the ear (most often seen in young children)"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-you-relieve-inner-ear-pressure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-you-relieve-inner-ear-pressure",
    "aria-label": "how do you relieve inner ear pressure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do you relieve inner ear pressure?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many simple home remedies for easing the feeling of fullness in the ears. Which remedy you use depends on what is causing the pressure."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To relieve airplane ear or ear pressure after an altitude change — like driving in the mountains, scuba diving or taking an elevator to a high floor — try yawning or swallowing. These actions open the eustachian tube so the pressure can equalize. Though not recommended for children, over-the-counter (OTC) nasal decongestant sprays can also help relieve ear pressure for adults since they can reduce sinus inflammation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Another remedy for instant ear pressure relieve is called the Valsalva maneuver. To use this method, close your mouth, pinch your nostrils closed, then, with your nose pinched, gently blow as if blowing your nose."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For children over age four who are experiencing ear discomfort during a flight, ", React.createElement("a", {
    href: "https://www.mayoclinic.org/diseases-conditions/airplane-ear/symptoms-causes/syc-20351701",
    target: "_blank"
  }, "the Mayo Clinic suggests"), " chewing gum to help the eustachian tubes open. Children can also try drinking through a straw or blowing bubbles through a straw to relieve pressure."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inner ear pressure caused by sinus infection can be treated with OTC decongestants (oral or nasal spray). OTC pain relievers can also be used to alleviate pain and swelling. Using a neti pot to rinse your sinuses can also help if a blockage is causing the pressure."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During an ear infection, fluid can accumulate behind the eardrum because the eustachian tubes aren’t draining correctly. Many ear infections resolve without antibiotics. To relieve pain, use OTC pain medication or ear drops. In some cases, a doctor may prescribe antibiotics for a bacterial infection."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Inner ear pressure or stuffiness from colds or allergies are caused by nasal inflammation and mucus, creating a blockage that prevents pressure equalization. Taking antihistamines (like Claritin or Zyrtec) or using corticosteroid nasal sprays (like Flonase) can reduce both inflammation and mucus production, which can allow your ear pressure to normalize."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If ear stuffiness is caused by ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/earwax/",
    className: "c-md-a"
  }, "earwax buildup"), " blocking the ear canal, removal of the wax is needed to relieve the fullness. Beware of common home remedies like using baby oil or hydrogen peroxide to break up wax, as these methods can damage your ear canal. Instead, consult a physician for manual wax removal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For a foreign object stuck in the ear, do not try to remove the object with a cotton swab or similar tool since this may push the object deeper into the ear canal. Instead, if the object is clearly visible and can be grasped, use tweezers to remove it gently. If the object is not accessible with tweezers, try washing the object out using a rubber bulb ear syringe and warm water. If home removal fails or the person is experiencing pain, ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), " or discharge from the ear, seek medical help."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-prevent-inner-ear-pressure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-prevent-inner-ear-pressure",
    "aria-label": "how to prevent inner ear pressure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to prevent inner ear pressure"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While there are many at-home remedies for inner ear pressure, there are also practical ways to prevent the condition from occurring. Avoid air travel if you have a cold, sinus infection, nasal congestion or ear infection. When flying, staying awake during takeoff and landing will allow you to take steps to help your ears adjust more comfortably. If flying with infants, give them a bottle during takeoff and landing to encourage swallowing, which will help their ears adjust."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "when-to-see-a-doctor-about-inner-ear-pressure",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#when-to-see-a-doctor-about-inner-ear-pressure",
    "aria-label": "when to see a doctor about inner ear pressure permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "When to see a doctor about inner ear pressure"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While most causes of inner ear pressure are easy to treat, fullness in the ear may also have uncommon causes, including TMJ (temporomandibular joint) disorders or ", React.createElement("a", {
    href: "https://www.nidcd.nih.gov/health/menieres-disease",
    target: "_blank"
  }, "Meniere’s disease"), ". You should see a doctor if there is severe pain, bleeding, dizziness, a foreign object that cannot be removed at home or if the pressure worsens despite at-home treatment. In cases where the ear pressure is recurrent, your doctor may do a surgical treatment called a myringotomy. During this procedure, your doctor will make a small incision in the eardrum to drain out fluid. This incision may be left open to heal, or the patient may have a small tube placed in the eardrum to aid in draining."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "permanent-damage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#permanent-damage",
    "aria-label": "permanent damage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Permanent damage"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In severe cases, ear barotrauma can damage the eardrum, result in bleeding or other damage to the ear. This type of trauma is common in scuba divers. If the condition is severe or prolonged without treatment, complications can occur, including ongoing tinnitus or ringing of the ears or permanent hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "why-choose-hearcom-for-your-hearing-health",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-choose-hearcom-for-your-hearing-health",
    "aria-label": "why choose hearcom for your hearing health permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why Choose hear.com for Your Hearing Health"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we understand the importance of addressing ear and hearing issues promptly and effectively. We guarantee a smooth experience on your road to better hearing. Working exclusively with the top 2% of audiologists in the U.S., we ensure that you receive the highest standard of care tailored to your specific needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Don’t let ear pressure or any hearing concerns hold you back. Contact hear.com today to schedule a consultation and take the first step towards optimal hearing health. Your journey to better hearing starts with the best—choose hear.com."), "\n", React.createElement(LandingPageCta, {
    copy: "I want to manage my hearing loss",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
